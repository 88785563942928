<template>
  <v-container class='crud-content'>
    <ListLayout :title="title" :buttonActions="buttonActions">
      <template v-slot:content>
        <v-data-table
          :headers="header"
          :loading="loading"
          :items="content"
          class="elevation-1 custom-table"
          :options.sync="options"
          :server-items-length="totalElements"
          :footer-props="{
            'items-length': 100,
            'items-per-page-text': 'Linhas por página',
            'items-per-page-options': [10, 20, 50],
            pageText: '{0}-{1} de {2}',
          }"
        >
          <template v-slot:[`item.cnpj`]="{ item }">
            <span>
              {{ formatCNPJ(item.cnpj) }}
            </span>
          </template>
          <template v-slot:[`item.originData`]="{ item }">
            <v-chip>
              {{ item.originData.toUpperCase() }}
            </v-chip>
          </template>
          <template v-slot:[`item.integrationPlatform`]="{ item }">
            <v-chip>
              {{ item.integrationPlatform.toUpperCase() }}
            </v-chip>
          </template>
          <template v-slot:[`item.productAutoApprove`]="{ item }">
            <v-icon :color="item.productAutoApprove ? '#02D13F' : 'red'">
              {{ item.productAutoApprove ? 'mdi-check' : 'mdi-close' }}
            </v-icon>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <div class="d-flex justify-center">
              <v-icon color="primary" @click="openDetail(item.id)">mdi-pencil</v-icon>
            </div>
          </template>
          <template v-slot:no-data>
            <div class="pt-10 pb-10">
              Nenhum resultado encontrado
            </div>
          </template>
        </v-data-table>
      </template>
    </ListLayout>
  </v-container>
</template>

<script>
import dataCrudMixin from '../../components/template/crud/mixins/dataCrudMixin';
import notification from '@/service/notification';
import getAllListSellers from '../../service/seller/seller-service';
import ListLayout from '@/components/template/list/ListLayout';
import { routerPush } from '@/service/kplaceRouter';

export default {
  components: {
    ListLayout,
  },
  watch: {
    options: {
      handler() {
        this.init();
      },
    },
  },
  async mounted() {
    if (this.options.sortBy.length === 0) {
        this.options.sortBy.push('companyName');
        this.options.sortDesc.push(true);
    }
    await this.init();
  },
  mixins: [dataCrudMixin],
  data() {
    return {
      search: '',
      loading: false,
      buttonActions: [{ name: 'CADASTRAR SELLER', action: () => routerPush('/seller/forms') }],
      options: {},
      title: 'Sellers',
      header: [
        { text: 'Nome fantasia', align: 'start', value: 'companyName', sortable: true },
        { text: 'CNPJ', value: 'cnpj', sortable: false },
        { text: 'Ramo de atividade', value: 'activity', sortable: true },
        { text: 'Aprovação de produtos', align: 'center', value: 'productAutoApprove', sortable: true },
        { text: 'Origem', value: 'originData', sortable: true },
        { text: 'Plataforma', value: 'integrationPlatform', sortable: true },
        { text: 'Ações', value: 'actions', sortable: false, align: 'center' },
      ],
      content: [],
      filters: [
        {
          xs: '12',
          sm: '12',
          md: '4',
          lg: '4',
          xl: '4',
          cols: '12',
          name: 'ramoAtividade',
          label: 'Ramo de atividade',
          placeHolder: 'Todos',
          type: 'select',
          filled: true,
          options: [
            {
              label: '',
              value: '',
            },
          ],
        },
        {
          xs: '12',
          sm: '12',
          md: '4',
          lg: '4',
          xl: '4',
          cols: '12',
          name: 'aprovacaoProdutos',
          label: 'Aprovação de produtos',
          placeHolder: 'Todas',
          type: 'select',
          filled: true,
          options: [
            {
              label: '',
              value: '',
            },
          ],
        },
        {
          xs: '12',
          sm: '12',
          md: '4',
          lg: '4',
          xl: '4',
          cols: '12',
          name: 'status',
          label: 'Status',
          placeHolder: 'Todos',
          type: 'select',
          filled: true,
          options: [
            {
              label: '',
              value: '',
            },
          ],
        },
        {
          xs: '12',
          sm: '12',
          md: '8',
          lg: '8',
          xl: '8',
          cols: '12',
          name: 'search',
          label: 'Pesquisar',
          placeHolder: 'Pesquise por sellers...',
          type: 'text',
          filled: true,
          prependInnerIcon: 'mdi-magnify',
        },
      ],
    };
  },
  methods: {
    async init() {
      try {
        this.loading = true;
        const { sortBy, sortDesc, page, itemsPerPage } = this.options;
        const { data } = await getAllListSellers(page, itemsPerPage, this.query, sortBy, sortDesc);
        this.totalElements = data.totalRegister;
        this.content = data.register;
      } catch (e) {
        notification(`Erro ao realizar busca de sellers : ${e}`, 'error');
      } finally {
        this.loading = false;
      }
    },
    openDetail(id) {
      routerPush(`/seller/forms/edit/${id}`);
    },
    formatCNPJ(cnpj) {
      cnpj = cnpj.replace(/\D/g, '');
      cnpj = cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, '$1.$2.$3/$4-$5');
      return cnpj;
    },
  },
};
</script>

<style>
  .crud-content {
      background-color: #f8f8f8;
      height: calc(100% - 160px);
      width: 100%;
      margin-top: 126px;
      border-radius: 15px;
      padding: 20px 20px 20px 20px;
  }
</style>
